/* istanbul ignore file */
import { useState } from "react";
import { useLocation } from "react-router";
import { isBrowser } from "~/utils/isBrowser";

export function useReportWebVitals() {
  const { pathname: currentLocation } = useLocation();
  const [previousLocation, setPreviousLocation] = useState("");

  if (isBrowser() && currentLocation !== previousLocation) {
    setPreviousLocation(currentLocation);

    const globalObj = getGlobalObject<Window>();

    // @ts-expect-error datadog config
    if (!globalObj.DD_RUM) {
      console.warn(
        "@datadog/rum-react-integration: Datadog RUM SDK is not initialized.",
      );
      return;
    }

    // @ts-expect-error datadog config
    if (!globalObj.DD_RUM?.startView) {
      console.warn(
        "@datadog/rum-react-integration: Manual tracking not supported. Try updating the Datadog RUM SDK.",
      );
      return;
    }

    const manualTracking =
      // @ts-expect-error datadog config
      !!globalObj.DD_RUM?.getInitConfiguration()?.trackViewsManually;
    if (!manualTracking) {
      console.warn(
        "@datadog/rum-react-integration: The trackViewsManually flag in RUM initialization must be set to %ctrue%c.",
        "color:green",
        "color:default",
      );
      return;
    }

    // @ts-expect-error datadog config
    globalObj.DD_RUM.startView(currentLocation);
  }
}

export function getGlobalObject<T>(): T {
  if (typeof globalThis === "object") {
    return globalThis as unknown as T;
  }
  Object.defineProperty(Object.prototype, "_dd_temp_", {
    get() {
      return this as object;
    },
    configurable: true,
  });
  // @ts-expect-error _dd_temp is defined using defineProperty
  let globalObject: unknown = _dd_temp_;
  // @ts-expect-error _dd_temp is defined using defineProperty
  // biome-ignore lint/performance/noDelete: <explanation>
  delete Object.prototype._dd_temp_;
  if (typeof globalObject !== "object") {
    // on safari _dd_temp_ is available on window but not globally
    // fallback on other browser globals check
    if (typeof self === "object") {
      globalObject = self;
    } else if (typeof window === "object") {
      globalObject = window;
    } else {
      globalObject = {};
    }
  }
  return globalObject as T;
}
